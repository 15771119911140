import React from "react"

import Layout from "../components/layout/layout"
import SEO from "../components/seo"

import "./impressum.scss";

export default class Impressum extends React.Component {
  render() {
    return (
      <Layout>
        <section id="imprint">
          <SEO title="Impressum" />
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-md-8">
                <h1>Impressum</h1>
                <p>
                  <strong>Alexander Ruoß Bauunternehmen</strong>
                  <br /> Forchenweg 12<br /> 72290 Loßburg
                  <br />
                  <br /> tel: 07446/9543731<br /> fax: 07446/9543732
                  <br /> mail: info@ruoss-bau.de<br />
                </p>

                <p>
                  Geschäftsführung: Alexander Ruoß
                  <br />
                  <br />
                </p>

                <h2>Urheberrecht</h2>
                <p>
                  Die durch die Seitenbetreiber erstellten Inhalte und Werke auf
                  diesen Seiten unterliegen dem deutschen Urheberrecht. Die
                  Vervielfältigung, Bearbeitung, Verbr/eitung und jede Art der
                  Verwertung außerhalb der Grenzen des Urheberrechtes bedürfen
                  der schriftlichen Zustimmung des jeweiligen Autors bzw.
                  Erstellers. Downloads und Kopien dieser Seite sind nur für den
                  privaten, nicht kommerziellen Gebr/auch gestattet. Soweit die
                  Inhalte auf dieser Seite nicht vom Betreiber erstellt wurden,
                  werden die Urheberrechte Dritter beachtet. Insbesondere werden
                  Inhalte Dritter als solche gekennzeichnet. Sollten Sie
                  trotzdem auf eine Urheberrechtsverletzung aufmerksam werden,
                  bitten wir um einen entsprechenden Hinweis. Bei Bekanntwerden
                  von Rechtsverletzungen werden wir derartige Inhalte umgehend
                  entfernen.
                </p>
              </div>
            </div>
          </div>
        </section>
      </Layout>
    );
  }
}

